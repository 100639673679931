define("ember-gesture-modifiers/modifiers/did-pan", ["exports", "ember-modifier", "ember-gesture-modifiers/utils/parse-touch-data", "@ember/object"], function (_exports, _emberModifier, _parseTouchData, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const _fn = () => {};
  let DidPanModifier = _exports.default = (_class = class DidPanModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "threshold", void 0);
      _defineProperty(this, "axis", void 0);
      _defineProperty(this, "capture", void 0);
      _defineProperty(this, "preventScroll", void 0);
      _defineProperty(this, "pointerTypes", void 0);
      _defineProperty(this, "currentTouches", new Map());
      _defineProperty(this, "dragging", false);
    }
    addEventListeners() {
      // By default, CSS rule `touch-action` is `auto`, enabling panning on both directions.
      // We override panning on a given direction, so we need to disable default browser behavior
      // on that diretion, but we need to keep the other direction pannable.
      // Thus, we set `touch-action` to `pan-y` when we pan horizontally and vice versa.
      if (this.axis === 'horizontal') {
        this.element.style.touchAction = 'pan-y';
      } else if (this.axis === 'vertical') {
        this.element.style.touchAction = 'pan-x';
      } else if (this.axis === 'both') {
        this.element.style.touchAction = 'none';
      }
      this.element.addEventListener('pointerdown', this.didTouchStart, {
        capture: this.capture,
        passive: true
      });
      document.addEventListener('pointermove', this.documentPointerMove, {
        capture: this.capture,
        passive: !this.preventScroll
      });
      document.addEventListener('pointercancel', this.documentPointerUp, {
        capture: this.capture,
        passive: true
      });
      document.addEventListener('pointerup', this.documentPointerUp, {
        capture: this.capture,
        passive: true
      });
    }
    removeEventListeners() {
      this.element.style.touchAction = null;
      this.element.removeEventListener('pointerdown', this.didTouchStart, {
        capture: this.capture,
        passive: true
      });
      document.removeEventListener('pointermove', this.documentPointerMove, {
        capture: this.capture,
        passive: !this.preventScroll
      });
      document.removeEventListener('pointercancel', this.documentPointerUp, {
        capture: this.capture,
        passive: true
      });
      document.removeEventListener('pointerup', this.documentPointerUp, {
        capture: this.capture,
        passive: true
      });
    }
    didTouchStart(e) {
      if (!this.dragging && this.pointerTypes.includes(e.pointerType)) {
        const touchData = (0, _parseTouchData.parseInitialTouchData)(e);
        this.currentTouches.set(e.pointerId, touchData);
        this.dragging = true;
      }
    }
    documentPointerMove(e) {
      if (this.dragging && this.pointerTypes.includes(e.pointerType)) {
        this.handlePointerMove(e);
      }
    }
    documentPointerUp(e) {
      if (this.dragging && this.pointerTypes.includes(e.pointerType)) {
        this.handlePointerEnd(e);
      }
    }
    handlePointerMove(e) {
      if (this.dragging && this.currentTouches.has(e.pointerId)) {
        const previousTouchData = this.currentTouches.get(e.pointerId);
        const touchData = (0, _parseTouchData.parseTouchData)(previousTouchData, e);
        if (touchData.panStarted) {
          // prevent scroll if a pan is still busy
          if (this.preventScroll) {
            e.preventDefault();
          }
          this.didPan(touchData.data);
        } else {
          // only pan when the threshold for the given axis is achieved
          if (!touchData.panDenied && (this.axis === 'horizontal' && Math.abs(touchData.data.current.distanceX) > this.threshold || this.axis === 'vertical' && Math.abs(touchData.data.current.distanceY) > this.threshold || this.axis === 'both' && Math.abs(touchData.data.current.distance) > this.threshold)) {
            // test if axis matches with data else deny the pan
            if (this.axis === 'horizontal' && (0, _parseTouchData.isHorizontal)(touchData) || this.axis === 'vertical' && (0, _parseTouchData.isVertical)(touchData) || this.axis === 'both') {
              // prevent scroll if a pan is detected
              if (this.preventScroll) {
                e.preventDefault();
              }
              touchData.panStarted = true;

              // trigger panStart hook
              this.didPanStart(touchData.data);
            } else {
              touchData.panDenied = true;
            }
          }
        }
        this.currentTouches.set(e.pointerId, touchData);
      }
    }
    handlePointerEnd(e) {
      if (this.dragging && this.currentTouches.has(e.pointerId)) {
        this.dragging = false;
        const previousTouchData = this.currentTouches.get(e.pointerId);
        const touchData = (0, _parseTouchData.parseTouchData)(previousTouchData, e);
        if (touchData.panStarted) {
          this.didPanEnd(touchData.data);
        }
        this.currentTouches.delete(e.pointerId);
      }
    }
    didReceiveArguments() {
      this.removeEventListeners();
      this.threshold = this.args.named.threshold ?? 10;
      this.axis = this.args.named.axis ?? 'horizontal';
      this.capture = this.args.named.capture ?? false;
      this.preventScroll = this.args.named.preventScroll ?? true;
      this.pointerTypes = this.args.named.pointerTypes ?? ['touch'];
      this.didPanStart = this.args.named.onPanStart ?? _fn;
      this.didPan = this.args.named.onPan ?? _fn;
      this.didPanEnd = this.args.named.onPanEnd ?? _fn;
      this.addEventListeners();
    }
    willRemove() {
      this.removeEventListeners();
      this.currentTouches.clear();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "didTouchStart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didTouchStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "documentPointerMove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "documentPointerMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "documentPointerUp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "documentPointerUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePointerMove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePointerMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePointerEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePointerEnd"), _class.prototype)), _class);
});